import React, { useState, useRef } from "react" // eslint-disable-line no-unused-vars
import PropTypes from "prop-types"
import { useThemeUI } from "theme-ui"
import { RoughNotation } from "react-rough-notation"
import { useNotationGroup } from "./notation-group"
import { selectables } from "./notation-selection"

const NotationSelectionSolution = (props) => {
  const { children, id, type, showAnswer } = props
  const { state } = useNotationGroup()
  const context = useThemeUI()
  const { theme } = context

  const selection = showAnswer
    ? state?.[id]?.answer?.selection
    : state?.[id]?.solution

  const currentSelected = selectables.find(
    (selectable) => selectable.id === selection
  )
  const currentColor = currentSelected
    ? theme.colors[currentSelected.color]
    : undefined

  return (
    <RoughNotation show={!!currentColor} type={type} color={currentColor}>
      {children}
    </RoughNotation>
  )
}

NotationSelectionSolution.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  showAnswer: PropTypes.bool,
}

NotationSelectionSolution.defaultProps = {
  type: "highlight",
  showAnswer: false,
}

export default NotationSelectionSolution
